<template>
    <div class="page-domains-register-domain-register">
        <div class="row">
            <div class="col-12">
                <div class="register-domain-box">
                    <app-loader v-if="loading" />

                    <div class="search-box">
                        <app-input v-model="domain_name" label="Enter your domain name" />
                    </div>

                    <div class="radio-buttons-box" v-if="has_at_least_one_zone">
                        <div class="category" v-for="(category, category_index) in categories" :key="category_index">
                            <div class="title">{{ category.title }}:</div>

                            <div class="radio-buttons">
                                <template v-for="(item, key) in category.zones">
                                    <div class="radio-button-container" :key="key">
                                        <input type="radio" :key="`item-${ item[keyValue] }`"
                                            :id="id(item)"

                                            :name="_uid"
                                            :value="item[keyValue]"
                                            :checked="(value || value != undefined) && item[keyValue] === value"

                                            :disabled="item.disabled"

                                            @click="handleChange(item)"
                                        >

                                        <label class="radio-button" :for="id(item)" :key="`label-${ key }`"
                                            :class="{ disabled: item.disabled }"
                                        >
                                            <div class="circle"></div>
                                            <div class="label">{{ item[keyLabel] }}</div>
                                        </label>

                                        <div class="price" :key="`price-${ key }`">{{ item.price }}</div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="actions-box">
                        <button class="btn btn-primary"
                            :disabled="loading || is_submit_button_disabled"

                            @click="submit"
                        >Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appInput from '@/components/app-input'

const DOMAIN_ZONES = [
    {
        CategoryName: 'New Zealand',
        Zones: [
            {
                UUID: 'e42c907b-14c9-4d69-b345-c496fd43650b',
                ZoneName: '.nz',
                Cents: 2999
            },
            {
                UUID: 'b79f6acc-ad46-4b73-ac90-e232609414c9',
                ZoneName: '.kiwi',
                Cents: 2999
            },
            {
                UUID: '1d433019-2f3e-4f59-af41-ad75722707b8',
                ZoneName: '.co.nz',
                Cents: 2999
            },
            {
                UUID: '9349f49f-fa42-4410-9285-e0c8532aa96a',
                ZoneName: '.net.nz',
                Cents: 2999
            },
            {
                UUID: 'fa1a52db-05e9-4cbf-bf55-b983da6670e3',
                ZoneName: '.org.nz',
                Cents: 2999
            },
            {
                UUID: 'e2609a90-d6ba-47f1-b202-6228f6dac37f',
                ZoneName: '.gen.nz',
                Cents: 2999
            },
            {
                UUID: '3624846f-adb8-4341-9315-41df1855b40b',
                ZoneName: '.ac.nz',
                Cents: 2999
            },
        ],
    },
    {
        CategoryName: 'Global',
        Zones: [
            {
                UUID: '55534941-8f8e-4ce9-afc9-a9a73f2c5eab',
                ZoneName: '.com',
                Cents: 2999
            },
            {
                UUID: '39c1d238-fe6c-43ad-a99e-32cf0ba02eb8',
                ZoneName: '.co',
                Cents: 2999
            },
            {
                UUID: '877d6855-5b5e-4c86-bb66-4aa7533e3da8',
                ZoneName: '.net',
                Cents: 2999
            },
            {
                UUID: '79cb8145-1287-42c4-b73f-3aa4b4f0d857',
                ZoneName: '.org',
                Cents: 2999
            },
            {
                UUID: '73abfb8d-3902-4158-9246-78231a289046',
                ZoneName: '.info',
                Cents: 2999
            },
            {
                UUID: '6d4f44a7-468a-4234-b3fb-6ace75570f53',
                ZoneName: '.me',
                Cents: 2999
            },
        ],
    },
    {
        CategoryName: 'United Kingdom',
        Zones: [
            {
                UUID: 'e631ade0-5c9b-44bd-8ae5-b3282a1c9da9',
                ZoneName: '.uk',
                Cents: 2999
            },
            {
                UUID: '8bb36956-a670-49db-a9f0-276bac11bbf5',
                ZoneName: '.co.uk',
                Cents: 2999
            },
            {
                UUID: 'f640bccf-7796-4ecb-9676-c201fa55b76f',
                ZoneName: '.org.uk',
                Cents: 2999
            },
            {
                UUID: 'ecfeea46-4700-4802-940d-11a811a4ea9d',
                ZoneName: '.me.uk',
                Cents: 2999
            }
        ],
    },
]

export default {
    components: {
        appLoader,
        appInput,
    },

    data() {
        return {
            keyValue: 'value',
            keyLabel: 'label',
            returnItem: false,

            domain_name: '',
            value: null,

            domain_zones: [],

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getDomainZones()
        },

        id(item) {
            return `${ item[this.keyValue] }-${this._uid}`
        },

        handleChange(item) {
            const value = item[this.keyValue]

            this.value = value
        },

        getDomainZones() {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                this.domain_zones = DOMAIN_ZONES

                this.loading = false
            }, delay_in_ms)
        },

        submit() {
            const domain_name = this.domain_name
            const zone        = this.value

            const domains = `${domain_name}${zone}`

            this.$router.push({
                name: 'domains-register-domain-confirm-registration',
                query: {
                    domains,
                }
            })
        },
    },

    computed: {
        is_submit_button_disabled() {
            const domain_name = this.domain_name
            const zone   = this.value

            return domain_name.trim().length == 0 || !zone
        },

        has_at_least_one_zone() {
            let result = false

            for (let i = 0, len = this.domain_zones.length; i < len; i++) {
                if (Array.isArray(this.domain_zones[i].Zones) && this.domain_zones[i].Zones.length) {
                    result = true
                    break
                }
            }

            return result
        },

        categories() {
            const categories = []

            for (let i = 0, len = this.domain_zones.length; i < len; i++) {
                const category = {
                    title: this.domain_zones[i].CategoryName,
                    zones: this.domain_zones[i].Zones.map(zone => ({
                        uuid: zone.UUID,
                        value: zone.ZoneName,
                        label: zone.ZoneName,
                        price: `$${ ( zone.Cents / 100 ).toFixed(2) }`,
                    }))
                }

                categories.push(category)
            }

            return categories
        },
    },
}
</script>

<style lang="scss">
.page-domains-register-domain-register {
    .register-domain-box {
        position: relative;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-primary);

        .search-box {
            padding: 24px 24px 40px;

            .app-input {
                max-width: 1162px;
            }
        }

        .radio-buttons-box {
            display: flex;
            flex-wrap: wrap;

            padding: 0 12px;

            .category {
                flex: 0 0 33.33%;
                max-width: 33.33%;

                margin-bottom: 40px;
                padding: 0 12px;

                .title {
                    font-size: 20px;
                    line-height: 22px;
                    margin-bottom: 24px;
                }

                .radio-buttons {
                    max-width: 300px;

                    .radio-button-container {
                        display: flex;
                        justify-content: space-between;

                        margin-bottom: 16px;

                        input[type=radio] {
                            display: none;

                            &+label {
                                display: flex;
                                align-items: center;
                                width: fit-content;
                                cursor: pointer;

                                &>.circle {
                                    position: relative;
                                    width: 18px;
                                    height: 18px;
                                    background-color: transparent;
                                    border: 2px solid var(--color-btn-primary-bg);
                                    border-radius: 50%;
                                    flex-shrink: 0;

                                    &:after {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        width: 8px;
                                        height: 8px;
                                        opacity: 0;
                                        background: var(--color-btn-primary-bg);
                                        border-radius: 50%;
                                        transition: opacity $transition-duration-primary;
                                    }
                                }

                                &>.label {
                                    margin-left: 8px;
                                    font-size: 16px;
                                    line-height: 1.5;
                                    color: var(--color-radio-btns-label);
                                    user-select: none;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &:hover {
                                    &>.circle {
                                        border-color: var(--color-btn-primary-bg-hover);

                                        &:after {
                                            background: var(--color-btn-primary-bg-hover);
                                        }
                                    }
                                }

                                &.disabled {
                                    cursor: default;
                                    opacity: .4;
                                    pointer-events: none;

                                    &>.circle {
                                        border-color: var(--color-btn-primary-bg);

                                        &:after {
                                            background: var(--color-btn-primary-bg);
                                        }
                                    }
                                }
                            }

                            &:checked {
                                &+label {
                                    .circle {
                                        &:after {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }

                        .price {
                            color: var(--color-table-secondary);
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .actions-box {
            padding: 0 24px 24px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-domains-register-domain-register {
        .register-domain-box {
            .radio-buttons-box {
                .category {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
        }

        .actions-box {
            .btn {
                max-width: 170px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-domains-register-domain-register {
        .register-domain-box {
            .search-box {
                padding: 16px 8px 24px;
            }

            .radio-buttons-box {
                padding: 0 4px;

                .category {
                    flex: 0 0 100%;
                    max-width: 100%;

                    margin-bottom: 30px;
                    padding: 0 4px;

                    .title {
                        font-size: 16px;
                    }

                    .radio-buttons {
                        max-width: 100%;

                        .radio-button-container {
                            input[type=radio] {
                                &+label {
                                    &>.label {
                                        font-size: 14px;
                                        line-height: 16px;
                                        margin-left: 4px;
                                    }
                                }
                            }

                            .price {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .actions-box {
                padding: 0 8px 16px;
            }
        }
    }
}
</style>